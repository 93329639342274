module.exports = {
  "$schema":"http://iglucentral.com/schemas/com.snowplowanalytics.self-desc/schema/jsonschema/1-0-0#",
  "self": {
    "vendor": "com.codecombat",
    "name": "mine_explored",
    "format": "jsonschema",
    "version": "1-0-0"
  },
  "type": "object",
  "properties": {
    "user": {"type": "string", "pattern": "^[a-z0-9]{24}$", "maxLength": 24, "minLength": 24},
    "engage_action": {
      "type": "string",
      "enum": [
        "campaign_level_click",
        "buy_button_click",
        "notify_check_box_checked",
        "submit_button_click"
      ]
    }
  },
  "required": ["user", "engage_action"],
  "additionalProperties": false
}
;
var __templateData = function anonymous(locals
/**/) {
var buf = [];
var locals_ = (locals || {}),view = locals_.view;buf.push("<div class=\"modal-dialog\"><div class=\"modal-content\">");
if ( view.state === 'purchasing')
{
buf.push("<div data-i18n=\"buy_gems.purchasing\" class=\"alert alert-info\"></div>");
}
else if ( view.state === 'retrying')
{
buf.push("<div id=\"retrying-alert\" data-i18n=\"buy_gems.retrying\" class=\"alert alert-danger\"></div>");
}
else
{
buf.push("<img id=\"subscribe-background\" src=\"/images/pages/play/modal/subscribe-background-blank.png\"/><h1 data-i18n=\"subscribe.subscribe_modal_title\"></h1><div id=\"close-modal\"><span class=\"glyphicon glyphicon-remove\"></span></div><div class=\"paper-area\"><div data-i18n=\"[html]subscribe.comparison_blurb\" class=\"benefits-header text-center\"></div><div class=\"container-fluid\"><div class=\"row\"><div class=\"col-xs-5 feature-col col-xs-offset-1\"><ul><li" + (jade.attrs({ 'data-i18n':("subscribe.feature_levels"), 'data-i18n-options':({premiumLevelsCount:view.i18nData.premiumLevelsCount}) }, {"data-i18n":true,"data-i18n-options":true})) + "></li>");
if ( view.basicProduct)
{
buf.push("<li" + (jade.attrs({ 'data-i18n':("subscribe.feature_gems"), 'data-i18n-options':({gems:view.basicProduct.get('gems')}) }, {"data-i18n":true,"data-i18n-options":true})) + "></li>");
}
buf.push("<li data-i18n=\"subscribe.feature_heroes\"></li></ul></div><div class=\"col-xs-5 feature-col\"><ul><li data-i18n=\"subscribe.feature_games\"></li><li data-i18n=\"subscribe.feature_websites\"></li><li data-i18n=\"subscribe.feature_items\"></li></ul></div></div><hr/>");
var price_mixin = function(name, p){
var block = this.block, attributes = this.attributes || {}, escaped = this.escaped || {};
var origPrice = p.priceStringNoSymbol()
var salePrice = p.adjustedPriceStringNoSymbol()
if ( origPrice == salePrice)
{
buf.push("<div" + (jade.attrs({ 'data-i18n':(name), 'data-i18n-options':({price:origPrice}), "class": [('price')] }, {"data-i18n":true,"data-i18n-options":true})) + "></div>");
}
else
{
buf.push("<div><span" + (jade.attrs({ 'data-i18n':(name), 'data-i18n-options':({price:origPrice}), "class": [('old-price')] }, {"data-i18n":true,"data-i18n-options":true})) + "></span><span" + (jade.attrs({ 'data-i18n':(name), 'data-i18n-options':({price:salePrice}), "class": [('price')] }, {"data-i18n":true,"data-i18n-options":true})) + "></span></div>");
}
};
buf.push("<div class=\"row\">");
var secondRowClass = '.col-xs-5'
if ( view.basicProduct)
{
buf.push("<div class=\"col-xs-5 option-col col-xs-offset-1\"><div data-i18n=\"subscribe.stripe_description\" class=\"option-header text-center\"></div>");
price_mixin("subscribe.month_price", view.basicProduct);
buf.push("<button class=\"btn btn-lg btn-illustrated purchase-button\">Subscribe Now</button></div>");
}
else
{
var secondRowClass = '.col-xs-12'
}
if ( view.lifetimeProduct)
{
buf.push("<div" + (jade.attrs({ "class": [('option-col'),(secondRowClass)] }, {"class":true})) + "><div data-i18n=\"subscribe.lifetime\" class=\"option-header text-center\"></div>");
price_mixin("subscribe.lifetime_price", view.lifetimeProduct);
buf.push("<button class=\"btn btn-lg btn-illustrated lifetime-button\">Buy Now</button></div>");
}
if ( view.yearProduct)
{
buf.push("<div" + (jade.attrs({ "class": [('option-col'),(secondRowClass)] }, {"class":true})) + "><div data-i18n=\"subscribe.year_subscription\" class=\"option-header text-center\"></div>");
price_mixin("subscribe.year_price",view.yearProduct);
buf.push("<button class=\"btn btn-lg btn-illustrated sale-button\">Buy Now</button></div>");
}
buf.push("</div></div><p> <span data-i18n=\"subscribe.kids_message_1\"></span>" + (jade.escape(null == (jade.interp = " ") ? "" : jade.interp)) + "<a data-i18n=\"subscribe.kids_message_2\" class=\"parent-link\"></a></p><p><span data-i18n=\"subscribe.support_part1\"></span>" + (jade.escape(null == (jade.interp = " ") ? "" : jade.interp)) + "<a href=\"mailto:support@codecombat.com\" data-i18n=\"subscribe.support_part2\"></a>" + (jade.escape(null == (jade.interp = " ") ? "" : jade.interp)) + "<span data-i18n=\"subscribe.support_part3\"></span></p></div>");
if ( view.state === 'declined')
{
buf.push("<div id=\"declined-alert\" class=\"alert alert-danger alert-dismissible\"><span data-i18n=\"buy_gems.declined\"></span><button type=\"button\" data-dismiss=\"alert\" class=\"close\"><span aria-hidden=\"true\">&times;</span></button></div>");
}
if ( view.state === 'unknown_error')
{
buf.push("<div id=\"error-alert\" class=\"alert alert-danger alert-dismissible\"><button type=\"button\" data-dismiss=\"alert\" class=\"close\"><span aria-hidden=\"true\">&times;</span></button><p data-i18n=\"loading_error.unknown\"></p><p>" + (jade.escape(null == (jade.interp = view.stateMessage) ? "" : jade.interp)) + "</p></div>");
}
}
buf.push("<div class=\"parent-link-popover-content hidden\"><div class=\"email-parent-form\"><p data-i18n=\"subscribe.parent_email_description\"></p><form><div class=\"form-group\"><label data-i18n=\"subscribe.parent_email_input_label\"></label><input type=\"email\" data-i18n=\"[placeholder]subscribe.parent_email_input_placeholder\" class=\"parent-input form-control\"/><div data-i18n=\"subscribe.parent_email_input_invalid\" class=\"parent-email-validator email_invalid\"></div></div><button type=\"submit\" data-i18n=\"subscribe.parent_email_send\" class=\"parent-send btn btn-default\"></button></form></div><div class=\"email-parent-complete\"><p data-i18n=\"subscribe.parent_email_sent\"></p><button type=\"button\" onclick=\"$('.parent-link').popover('hide');\" data-i18n=\"modal.close\" class=\"btn\"></button></div></div></div></div>");;return buf.join("");
};
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}
var __templateData = function anonymous(locals
/**/) {
var buf = [];
buf.push("<div class=\"setup-account-panel text-left\"><div class=\"p-a-3\"><div v-if=\"saving\" class=\"text-center\">{{ $t(\"common.saving\") }}</div><div v-else-if=\"error\" class=\"text-center\">{{ $t(\"loading_error.error\") }}" + (jade.escape(null == (jade.interp = ": ") ? "" : jade.interp)) + "{{ error }}</div><div v-else=\"v-else\"><h3 class=\"text-center\">{{ $t(\"signup.teacher_ready_to_create_class\") }}</h3><p class=\"m-t-3\">{{ $t(\"signup.teacher_students_can_start_now\") }}</p><ol><li>{{ $t(\"signup.teacher_list_create_class\") }}</li><li>{{ $t(\"signup.teacher_list_add_students\") }}</li><li><span>{{ $t(\"signup.teacher_list_resource_hub_1\") }}</span>" + (jade.escape(null == (jade.interp = " ") ? "" : jade.interp)) + "<a href=\"/teachers/courses\">{{ $t(\"signup.teacher_list_resource_hub_2\") }}</a>" + (jade.escape(null == (jade.interp = " ") ? "" : jade.interp)) + "<span>{{ $t(\"signup.teacher_list_resource_hub_3\") }}</span>" + (jade.escape(null == (jade.interp = " ") ? "" : jade.interp)) + "<a href=\"/teachers/resources\">{{ $t(\"signup.teacher_list_resource_hub_4\") }}</a>" + (jade.escape(null == (jade.interp = " ") ? "" : jade.interp)) + "<span>{{ $t(\"signup.teacher_list_resource_hub_5\") }}</span></li></ol><span v-html=\"$t('signup.teacher_additional_questions', {supportEmail})\"></span></div></div><div class=\"history-nav-buttons\"><button type=\"button\" v-on:click=\"clickBack\" v-if=\"error\" class=\"back-button btn btn-lg btn-navy-alt\">{{ $t(\"common.back\") }}</button><button v-on:click=\"clickFinish\" v-else-if=\"!saving\" class=\"next-button btn btn-lg btn-navy\">{{ $t(\"signup.finish\") }}</button></div></div>");;return buf.join("");
};
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}
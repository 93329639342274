var __templateData = function anonymous(locals
/**/) {
var buf = [];
var locals_ = (locals || {}),view = locals_.view;buf.push("<form id=\"basic-info-form\" class=\"modal-body basic-info\">");
var path = view.signupState.get('path')
buf.push("<div class=\"modal-body-content\"><div class=\"auth-network-logins text-center\"><h4><span data-i18n=\"teachers_quote.connect_with\"></span></h4><a" + (jade.attrs({ 'id':('facebook-signup-btn'), 'disabled':(!view.signupState.get('facebookEnabled')), 'data-sso-used':("facebook"), "class": [('btn'),('btn-primary'),('btn-lg'),('btn-illustrated'),('network-login')] }, {"disabled":true,"data-sso-used":true})) + "><img src=\"/images/pages/modal/auth/facebook_sso_button.png\" draggable=\"false\" width=\"175\" height=\"40\" class=\"network-logo\"/><span data-i18n=\"login.sign_in_with_facebook\" class=\"sign-in-blurb\"></span></a><a" + (jade.attrs({ 'id':('gplus-signup-btn'), 'disabled':(!view.signupState.get('gplusEnabled')), 'data-sso-used':("gplus"), "class": [('btn'),('btn-danger'),('btn-lg'),('btn-illustrated'),('network-login')] }, {"disabled":true,"data-sso-used":true})) + "><img src=\"/images/pages/modal/auth/gplus_sso_button.png\" draggable=\"false\" width=\"175\" height=\"40\" class=\"network-logo\"/><span data-i18n=\"login.sign_in_with_gplus\" class=\"sign-in-blurb\"></span><div class=\"gplus-login-wrapper\"><div class=\"gplus-login-button\"></div></div></a></div><div class=\"hr-text\"><hr/><span data-i18n=\"general.or\"></span></div><div class=\"form-container\">");
if ( ['student', 'teacher'].indexOf(view.signupState.get('path')) !== -1)
{
buf.push("<div class=\"row full-name\"><div" + (jade.attrs({ "class": [('col-xs-offset-3'),((path === 'teacher' ? 'col-xs-3' : 'col-xs-5'))] }, {"class":true})) + "><div class=\"form-group\"><label for=\"first-name-input\" class=\"control-label\"><span data-i18n=\"general.first_name\"></span></label><input" + (jade.attrs({ 'id':('first-name-input'), 'name':("firstName"), 'value':(view.signupState.get('signupForm').firstName), "class": [('form-control'),('input-lg')] }, {"name":true,"value":true})) + "/></div></div><div" + (jade.attrs({ "class": [((path === 'teacher' ? 'col-xs-3' : 'col-xs-4'))] }, {"class":true})) + ">");
if ( view.signupState.get('path') === 'teacher')
{
buf.push("<div class=\"last-name form-group\"><label for=\"last-name-input\" class=\"control-label\"><span data-i18n=\"general.last_name\"></span></label><input" + (jade.attrs({ 'id':('last-name-input'), 'name':("lastName"), 'value':(view.signupState.get('signupForm').lastName), "class": [('form-control'),('input-lg')] }, {"name":true,"value":true})) + "/></div>");
}
else
{
buf.push("<div class=\"last-initial form-group\"><label for=\"last-name-input\" class=\"control-label\"><span data-i18n=\"general.last_initial\"></span></label><input" + (jade.attrs({ 'id':('last-name-input'), 'name':("lastName"), 'maxlength':("1"), 'value':(view.signupState.get('signupForm').lastName), "class": [('form-control'),('input-lg')] }, {"name":true,"maxlength":true,"value":true})) + "/></div>");
}
buf.push("</div></div>");
}
buf.push("<div class=\"form-group\"><div class=\"row\"><div class=\"col-xs-5 col-xs-offset-3\"><label for=\"email-input\" class=\"control-label\"><span data-i18n=\"share_progress_modal.form_label\"></span></label>");
if ( view.signupState.get('path') === 'student')
{
buf.push("<div class=\"help-block optional-help-block pull-right\"><span data-i18n=\"signup.optional\"></span></div>");
}
buf.push("<input" + (jade.attrs({ 'id':('email-input'), 'name':("email"), 'type':("email"), 'value':(view.signupState.get('signupForm').email), "class": [('form-control'),('input-lg')] }, {"name":true,"type":true,"value":true})) + "/></div><div class=\"col-xs-4 email-check fancy-error\">");
var checkEmailState = view.state.get('checkEmailState');
if ( checkEmailState === 'checking')
{
buf.push("<span data-i18n=\"signup.checking\" class=\"small\"></span>");
}
if ( checkEmailState === 'exists')
{
buf.push("<span class=\"small\"><span class=\"text-burgundy glyphicon glyphicon-remove-circle\"></span>" + (jade.escape(null == (jade.interp = " ") ? "" : jade.interp)) + "<span data-i18n=\"signup.account_exists\"></span>" + (jade.escape(null == (jade.interp = " ") ? "" : jade.interp)) + "<a data-i18n=\"signup.sign_in\" class=\"login-link\"></a></span>");
}
if ( checkEmailState === 'available')
{
buf.push("<span class=\"small\"><span class=\"text-forest glyphicon glyphicon-ok-circle\"></span>" + (jade.escape(null == (jade.interp = " ") ? "" : jade.interp)) + "<span data-i18n=\"signup.email_good\"></span></span>");
}
buf.push("</div></div></div><div class=\"form-group\"><div class=\"row\"><div class=\"col-xs-5 col-xs-offset-3\"><label for=\"username-input\" class=\"control-label\"><span data-i18n=\"general.username\"></span></label><input" + (jade.attrs({ 'id':('username-input'), 'name':("name"), 'value':(view.signupState.get('signupForm').name), "class": [('form-control'),('input-lg')] }, {"name":true,"value":true})) + "/></div><div class=\"col-xs-4 name-check fancy-error\">");
var checkNameState = view.state.get('checkNameState');
if ( checkNameState === 'checking')
{
buf.push("<span data-i18n=\"signup.checking\" class=\"small\"></span>");
}
if ( checkNameState === 'exists')
{
buf.push("<span class=\"small\"><span class=\"text-burgundy glyphicon glyphicon-remove-circle\"></span>" + (jade.escape(null == (jade.interp = " ") ? "" : jade.interp)) + "<span>" + (jade.escape(null == (jade.interp = view.state.get('suggestedNameText')) ? "" : jade.interp)) + "</span></span>");
}
if ( checkNameState === 'available')
{
buf.push("<span class=\"small\"><span class=\"text-forest glyphicon glyphicon-ok-circle\"></span>" + (jade.escape(null == (jade.interp = " ") ? "" : jade.interp)) + "<span data-i18n=\"signup.name_available\"></span></span>");
}
buf.push("</div></div></div><div class=\"form-group\"><div class=\"row\"><div class=\"col-xs-5 col-xs-offset-3\"><label for=\"password-input\" class=\"control-label\"><span data-i18n=\"general.password\"></span></label><input" + (jade.attrs({ 'id':('password-input'), 'name':("password"), 'type':("password"), 'value':(view.signupState.get('signupForm').password), "class": [('form-control'),('input-lg')] }, {"name":true,"type":true,"value":true})) + "/></div></div></div><div class=\"form-group checkbox subscribe\"><div class=\"row\"><div class=\"col-xs-7 col-xs-offset-3\"><div class=\"checkbox\"><label><input" + (jade.attrs({ 'id':('subscribe-input'), 'type':("checkbox"), 'name':("subscribe"), 'checked':(view.signupState.get('signupForm').subscribe[0] === 'on') }, {"type":true,"name":true,"checked":true})) + "/><span data-i18n=\"signup.email_announcements\"></span></label></div></div></div></div><div class=\"error-area\">");
var error = view.state.get('error');
if ( error)
{
buf.push("<div class=\"row\"><div class=\"col-xs-7 col-xs-offset-3\"></div></div><div class=\"alert alert-danger\">" + (jade.escape(null == (jade.interp = error) ? "" : jade.interp)) + "</div>");
}
buf.push("</div></div></div><!-- In reverse order for tabbing purposes--><div class=\"history-nav-buttons\">");
if ( view.signupState.get('path') === 'teacher')
{
buf.push("<button type=\"submit\" class=\"next-button btn btn-lg btn-navy\"><span data-i18n=\"common.next\"></span></button>");
}
else
{
buf.push("<button id=\"create-account-btn\" type=\"submit\" class=\"next-button btn btn-lg btn-navy\"><span data-i18n=\"login.sign_up\"></span></button>");
}
buf.push("<button type=\"button\" class=\"back-button btn btn-lg btn-navy-alt\"><span data-i18n=\"common.back\"></span></button></div></form>");;return buf.join("");
};
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}
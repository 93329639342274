module.exports = {
	"$schema": "http://iglucentral.com/schemas/com.snowplowanalytics.self-desc/schema/jsonschema/1-0-0#",
	"self": {
		"vendor": "com.codecombat",
		"name": "classroom_invite_via_email",
		"format": "jsonschema",
		"version": "1-0-0"
	},
	"type": "object",
	"properties": {
		"user": {"type": "string", "pattern": "^[a-z0-9]{24}$", "maxLength": 24, "minLength": 24},
		"classroomID": {"type": "string", "pattern": "^[a-z0-9]{24}$", "maxLength": 24, "minLength": 24},
		"emails": {
			"type": "array",
			"items": {
   				"type": "string",
   				"format": "email"
  			}
		}
	},
	"required": ["user", "classroomID"],
	"additionalProperties": false
}
;
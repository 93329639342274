module.exports = {
	"$schema": "http://iglucentral.com/schemas/com.snowplowanalytics.self-desc/schema/jsonschema/1-0-0#",
	"self": {
		"vendor": "com.codecombat",
		"name": "loaded_world_map",
		"format": "jsonschema",
		"version": "1-0-0"
	},
	"type": "object",
	"properties": {
		"user": {"type": "string", "pattern": "^[a-z0-9]{24}$", "maxLength": 24, "minLength": 24},
		"label": {"type": "string", "description":"The map that was loaded i.e. forest"}
	},
	"required": ["user", "label"],
	"additionalProperties": false
};
var __templateData = function anonymous(locals
/**/) {
var buf = [];
buf.push("<component :is=\"panel\" v-on:continue=\"onContinue\" v-on:back=\"onBack\" trial-request-attributes=\"trialRequestAttributes\" class=\"modal-body teacher-panel\"></component>");;return buf.join("");
};
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}
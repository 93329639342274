var __templateData = function anonymous(locals
/**/) {
var buf = [];
buf.push("<div v-bind:class=\"{ 'has-error': showRequired &amp;&amp; !value }\" class=\"form-group\"><span class=\"control-label\">{{ label }}" + (jade.escape(null == (jade.interp = " ") ? "" : jade.interp)) + "<strong v-if=\"showRequired &amp;&amp; !value\">{{ $t(\"common.required_field\") }}</strong></span><input v-bind:name=\"name\" v-model=\"value\" v-on:keyup.up=\"navSearchUp\" v-on:keyup.down=\"navSearchDown\" v-on:keyup.enter=\"navSearchChoose\" v-on:keyup.esc.stop=\"navSearchClear\" v-on:blur=\"onBlur\" v-on:input=\"onInput\" ref=\"focus\" autocomplete=\"off\" class=\"form-control\"/><div class=\"suggestion-wrapper\"><div class=\"list-group\"><div v-for=\"(suggestion, index) in suggestions\" v-bind:class=\"{ active: index === suggestionIndex }\" v-on:mouseover=\"suggestionHover(index)\" v-on:click=\"navSearchChoose\" v-on:mousedown.prevent=\"\" class=\"list-group-item\"><div v-if=\"displayKey === 'name'\" v-html=\"suggestion._highlightResult.name.value\" class=\"school\"></div><div class=\"district\"><span v-html=\"suggestion._highlightResult.district.value\">,</span>" + (jade.escape(null == (jade.interp = " ") ? "" : jade.interp)) + "<span v-if=\"suggestion._highlightResult.city\" v-html=\"suggestion._highlightResult.city.value\"></span>" + (jade.escape(null == (jade.interp = " ") ? "" : jade.interp)) + "<span v-html=\"suggestion._highlightResult.state.value\"></span></div></div></div></div></div>");;return buf.join("");
};
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}
module.exports = {
	"$schema": "http://iglucentral.com/schemas/com.snowplowanalytics.self-desc/schema/jsonschema/1-0-0#",
	"self": {
		"vendor": "com.codecombat",
		"name": "play_gamedev_level_load",
		"format": "jsonschema",
		"version": "1-0-0"
	},
	"type": "object",
	"properties": {
		"user": {"type": "string", "pattern": "^[a-z0-9]{24}$", "maxLength": 24, "minLength": 24},
		"courseID": {"type": "string", "pattern": "^[a-z0-9]{24}$", "maxLength": 24, "minLength": 24},
		"levelSlug": {"type": "string", "maxLength": 128},
		"sessionID": {"type": "string", "pattern": "^[a-z0-9]{24}$", "maxLength": 24, "minLength": 24},
		"levelID": {"type": "string", "pattern": "^[a-z0-9]{24}$", "maxLength": 24, "minLength": 24}

	},
	"required": ["user", "levelSlug", "levelID"],
	"additionalProperties": false
}
;
CocoModel = require './CocoModel'

module.exports = class GameUIState extends CocoModel
  @className: 'GameUIState'
  @schema: {
    type: 'object'
    properties: {
      
      canDragCamera: {
        type: 'boolean'
        description: 'Serves as a lock to enable or disable camera movement.'
      }
      
      selected: {
        # TODO: Turn this into a collection which can be listened to? With Thang models.
        type: 'object'
        description: 'Array of selected thangs'
        properties: {
          sprite: { description: 'Lank instance' }
          thang: { description: 'Thang object generated by the world' }
        }
      }
    }
  }

  defaults: -> {
    selected: []
    canDragCamera: true
    realTimeInputEvents: new Backbone.Collection()
  }

module.exports = {
	"$schema": "http://iglucentral.com/schemas/com.snowplowanalytics.self-desc/schema/jsonschema/1-0-0#",
	"self": {
		"vendor": "com.codecombat",
		"name": "identify",
		"format": "jsonschema",
		"version": "1-0-0"
	},
	"type": "object",
	"properties": {
		"user": {"type": "string", "pattern": "^[a-z0-9]{24}$", "maxLength": 24, "minLength": 24},
		"id": {"type": "string"},
		"traits": {
			"type": "object",
			"properties": {
				"host": {"type": "string"},
				"ageRange": {"type": "string", "pattern":"^\\d+-\\d+"},
				"lastLevel": {"type": "string"},
				"testGroupNumber": {"type": "integer"},
				"name": {"type": "string"},
				"dateCreated": {"type": "string", "format": "date-time"},
				"email": {"type": "string", "format": "email"},
				"anonymous": {"type": "boolean"},
				"role": {"type": "string"},
				"referrer": {"type": "string", "format":"url"},
				"hourOfCode": {"type": "boolean"},
				"announcesActionAudioGroup": {"type": "string"},
				"defaultLanguageGroup": {"type": "string"},
				"gender": {"enum": [ "male", "female", "secret", "other", "trans" ]},
				"yearSubscriptionGroup": {"type": "string"},
				"teacher": {"type": "boolean"},
				"schoolName": {"type": "string"},
				"priority": {"type": "string"}
			},
			"additionalProperties": false
		}
	},
	"required": ["user"],
	"additionalProperties": false
};
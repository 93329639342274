var __templateData = function anonymous(locals
/**/) {
var buf = [];
var locals_ = (locals || {}),view = locals_.view;buf.push("<!-- DNT--><div class=\"modal-dialog\"><div class=\"modal-content\"><img id=\"modal-background\" src=\"/images/pages/play/modal/announcement_modal_bg.png\"/><h1>Do you like Minecraft?</h1><div id=\"close-modal\"><span class=\"glyphicon glyphicon-remove\"></span></div><div id=\"mine-content\"><div id=\"image-content\"><img id=\"pickaxe\" src=\"/images/minecraft/icon_stone_pick.png\"/><img id=\"hands\" src=\"/images/minecraft/hans4.png\"/></div><div id=\"text-content\"><div id=\"details-header\">Play CodeCombat in Minecraft!</div><br/><div id=\"info-text\"><ul><li>Access a private Minecraft server where you can script the world</li><li>Write code to change the world around you</li><li>Control units to mine, build, and fight for your avatar</li></ul></div><div id=\"capacity-text\"><div>We are not currently accepting additional beta users for CodeCombat Minecraft, but hope to make more available in the future.</div><form id=\"notify-me-form\"><div class=\"checkbox\"><label><!-- NOTE: This needs to be written as HTML or it won't pick up contained text--><input id=\"notify-me-check\" type=\"checkbox\"> Yes, please notify me about the CodeCombat Minecraft beta</label></div></form></div><div id=\"button-container\"><button id=\"buy-now-button\" class=\"btn btn-lg btn-illustrated purchase-button\"><span>" + (jade.escape(null == (jade.interp = "Buy Now $" + (view.price ? view.price : "...") + "/") ? "" : jade.interp)) + "</span><span class=\"no-text-transform\">mo</span></button><button id=\"submit-button\" class=\"btn btn-lg btn-illustrated sale-button\">Submit</button></div></div></div></div></div>");;return buf.join("");
};
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}
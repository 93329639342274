module.exports = {
  "$schema": "http://iglucentral.com/schemas/com.snowplowanalytics.self-desc/schema/jsonschema/1-0-0#",
  "self": {
    "vendor": "com.codecombat",
    "name": "saw_victory",
    "format": "jsonschema",
    "version": "1-0-1"
  },
  "type": "object",
  "properties": {
    "user": {"type": "string", "pattern": "^[a-z0-9]{24}$", "maxLength": 24, "minLength": 24},
    "ls": {"type": "string", "pattern": "^[a-z0-9]{24}$", "maxLength": 24, "minLength": 24},
    "levelID": {"type": "string", "maxLength": 128},
    "level": {"type": "string"},
    "playtime": { "type": "integer", "minimum": 0, "maximum": 172800 }
  },
  "required": ["user", "levelID", "ls", "level"],
  "additionalProperties": false
}
;